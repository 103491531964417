import { Navigate, Routes, Route } from 'react-router-dom'
import LayoutPage from './pages/Layout'
import ProdutosPage from './pages/Produtos'
import ListarProdutos from './pages/Produtos/Listar'
import AdicionarProdutoPage from './pages/Produtos/Adicionar'
import MovimentosPage from './pages/Movimentos'
import ListarMovimentos from './pages/Movimentos/Listar'
import AdicionarMovimentoPage from './pages/Movimentos/Adicionar'
import UsuariosPage from './pages/Usuarios'
import AdicionarUsuarioPage from './pages/Usuarios/Adicionar'
import ListarUsuarios from './pages/Usuarios/Listar'
import Error404 from './pages/Errors/error404'
import EditarProdutoPage from './pages/Produtos/Editar'
import EditarUsuarioPage from './pages/Usuarios/Editar'

import { PrivateRoute } from './components/PrivateRoute'

import { AuthContextProvider } from './contexts/auth'
import LoginPage from './pages/Login'

export function AppRoutes2(){

    return (
        <AuthContextProvider>
            <Routes>
                <Route path="/login" element={<LoginPage />} />

                <Route path="/" element={<LayoutPage />}>
                    
                    <Route path="" element={<Navigate to="/produtos" />} />
                    <Route path="produtos" element={<PrivateRoute><ProdutosPage /></PrivateRoute>}>
                        <Route path="" element={<ListarProdutos />} />
                        <Route path="adicionar" element={<AdicionarProdutoPage />} />
                        <Route path="editar/:id" element={<EditarProdutoPage />} />
                    </Route>

                    <Route path="movimentos" element={<PrivateRoute><MovimentosPage/></PrivateRoute>} >
                        <Route path="" element={<ListarMovimentos/>} />
                        <Route path="adicionar" element={<AdicionarMovimentoPage/>} />
                    </Route>

                    <Route path="usuarios" element={<PrivateRoute><UsuariosPage/></PrivateRoute>}>
                        <Route path="" element={<ListarUsuarios/>} />
                        <Route path="adicionar" element={<AdicionarUsuarioPage/>} />
                        <Route path="editar/:id" element={<EditarUsuarioPage/>} />
                    </Route>

                    <Route path="*" element={<Error404/>} />
                
                </Route>
            </Routes>
        </AuthContextProvider>
    )
}
