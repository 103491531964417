import { useState, useEffect } from "react";
import Select, { CSSObjectWithLabel } from "react-select";
import api from "../../services/api";

export function SelectSearch({field, onChange}: any){

    const [options, setOptions] = useState([{value:0,label:""}]);

    useEffect(() => {
        const getData = async () => {
            const arr = [] as any
            await api.get(`/products.php`).then((res) => {
                let result = res.data
                result.map((product: any) => {
                    return arr.push({value: product.id, label: product.name})
                });
                setOptions(arr)
            });
        };
        getData();
      }, []);

    const selectStyle = {
        control: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            color: 'white',
            backgroundColor: '#2A3038',
            borderColor: '#2A3038',
        }),
        menuList: (provided: CSSObjectWithLabel) => ({
            ...provided,
            color: '#000'
        }),
        singleValue: (provided: CSSObjectWithLabel) => ({
            ...provided,
            backgroundColor: '#2A3038',
            color: '#fff'
        }),
        input: (provided: CSSObjectWithLabel) => ({
            ...provided,
            color: '#fff'
        }),
    }

    return (
        <Select 
            options={options} 
            styles={selectStyle} 
            placeholder="Digite para buscar..."
            onChange={onChange}
            {...field}
        />
    )
}