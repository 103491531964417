import { Navigate } from "react-router-dom"
import { useAuth } from "../../hooks/useAuth"

interface PrivateRouteProps {
    children: React.ReactNode | Element
}

export const PrivateRoute = ({children}: PrivateRouteProps) =>{
    const {auth, loading} = useAuth()
    
    if(loading){
        return (<div id="loading">Carregando...</div>)
    }

    if(!auth){
        return <Navigate to="/login"/>
    }

    return <>{children}</>
}