import { NavLink } from "react-router-dom";
import { CardComponent } from "../../../components/CardComponent";
import { Head } from "../../../components/Head";
import { TituloDescricao } from "../../../components/PagesTitles/style";

import dayjs from 'dayjs'

import {TbNewSection, TbTrash} from 'react-icons/tb'
import { useEffect, useState } from "react";
import api from "../../../services/api";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toast } from "react-toastify";
import { SelectSearch } from "../../../components/SelectSearchProduct";

interface MovesListaProps {
    id: string
    created: string
    notes: string
    operation: string
    name: string
    qty: string
}

export default function ListarMovimentos(){
    const [stock, setStock] = useState<number|string>()
    const [productId, setProductId] = useState<number|string>()

    const [moves, setMoves] = useState<Array<MovesListaProps>>([])
    useEffect(() => {
        (async () => {

            if(productId){
                let movesData = await api.get(`/movements.php?product_id=${productId}`)
                setMoves(movesData.data)
                const stock = await api.get(`/stocks.php?id=${productId}`)
                setStock(stock.data.qty)
            }else{
                let movesData = await api.get(`/movements.php`)
                setMoves(movesData.data)
            }
        })()
    }, [productId])



    const deleteMoviment = (id: string|number) => {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Deseja mesmo excluir?',
            text: 'Esta ação é irreversivel',
            showDenyButton: true,
            icon: 'warning',
            confirmButtonText: 'Sim',
            denyButtonText: 'Não'
        }).then(async (result) => {
            if(result.value){
                await api.delete(`/movements.php?id=${id}`);
                if(productId){
                    let movesData = await api.get(`/movements.php?product_id=${productId}`)
                    setMoves(movesData.data)
                }else{
                    let movesData = await api.get(`/movements.php`)
                    setMoves(movesData.data)
                }
                toast.success('Movimento removido com sucesso!')
            }
        })
    }

    return (
        <>
            <Head title="Movimentos" />
            <TituloDescricao>
                <div>
                    <h1>Movimentos</h1>
                    <p>Entradas, saídas, sobras e compras de materiais</p>
                </div>
                <div className="flex-row align-center">
                    {productId && <h1 className="mb-0 mr-20">Estoque atual: {stock}</h1>}
                    <NavLink className="btn btn-primary" to="/movimentos/adicionar">
                        <TbNewSection/>
                        Novo movimento
                    </NavLink>
                </div>
            </TituloDescricao>

            <CardComponent>
                <form>
                    <div className="col-3 pd-15">
                        <SelectSearch onChange={(e: any) => setProductId(e.value)} />
                    </div>
                </form>
                {moves.length ? 
                <table className="table">
                    <tr>
                        <th>Data</th>
                        <th>Produto</th>
                        <th>Quantidade</th>
                        <th>Observação</th>
                        <th>Ações</th>
                    </tr>
                    {moves.map(move => 
                    <tr>
                        <td>{dayjs(move.created).format('DD/MM/YYYY')}</td>
                        <td>{move.name}</td>
                        <td>
                            <small>{move.operation === 'in' ? 'Entrada' : 'Saída'}</small><br/> {move.qty}
                        </td>
                        <td>{move.notes}</td>
                        <td>
                            {/* <button className="btn-action"><TbEdit /></button> */}
                            <button className="btn-action danger" onClick={() => deleteMoviment(move.id)}><TbTrash /></button>
                        </td>
                    </tr>
                    )}
                </table>
                :
                <div className="pd-25 text-center">
                    <h2 className="mb-15">Nenhum registro encontrado</h2>
                    <p>Comece adicionando o primeito item </p>
                </div>
                }
            </CardComponent>
        </>
    )
}