import * as yup from "yup";

export const movimentoSchema = yup.object({
    product_id: yup.object().shape({
        label: yup.string().required(),
        value: yup.number().required()
    }).required('Campo obrigatório'),
    operation: yup.string().required('Campo obrigatório'),
    qty: yup.number().required('Campo obrigatório'),
    notes: yup.string(),
})

export type MovimentoType = yup.InferType<typeof movimentoSchema>