import { CardComponent } from "../../../components/CardComponent";
import { Head } from "../../../components/Head";
import { TituloDescricao } from "../../../components/PagesTitles/style";

import { NavLink, useNavigate } from "react-router-dom";
import slugify from 'react-slugify';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { ProductType, productSchema } from "./FormValidation";
import api from "../../../services/api";
import { toast } from "react-toastify";

export default function AdicionarProdutoPage(){

    const navigate = useNavigate();

    const {register, handleSubmit, formState: {errors}, getValues, setValue} = useForm({resolver: yupResolver(productSchema)})
    const generateCode = () => {
        const code = slugify(getValues('name'), { delimiter: '_' }).slice(0, 5).toUpperCase() + new Date().valueOf();
        setValue('reference', code)
    }
    
    async function onSubmit(data: ProductType){
        const addProduct = await api.post('/products.php', data);
        if(addProduct.data.success === true){
            toast.success(addProduct.data.message)
            navigate('/produtos');
        }else{
            toast.error(addProduct.data.message)
        }
    }

    return (
        <>
            <Head title="Produtos" />
            <TituloDescricao>
                <div>
                    <h1>Novo Produto</h1>
                    <p>Cadastre um novo produto</p>
                </div>
            </TituloDescricao>

            <CardComponent className="padding-15">
                <header>
                    <h3>Cadastro</h3>
                    <p>Preencha os campos abaixo com os dados para o cadastro</p>
                </header>
                <form onSubmit={handleSubmit(onSubmit)} className="col-6">
                    <div className="form-row">
                        <div className="col-3 flex-row align-center"><label htmlFor="" className="form-label">Nome do produto*</label></div>
                        <div className="col-9">
                            <input 
                                type="text" 
                                className={`form-control ${errors.name && 'error'}`} 
                                placeholder='Ex: Parafuso com porca 3/4"' 
                                {...register('name')} 
                                onBlur={() => generateCode()}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-3 flex-row align-center"><label htmlFor="" className="form-label">Código*</label></div>
                        <div className="col-9">
                            <input type="text" className={`form-control ${errors.reference && 'error'}`} placeholder='Ex: PARAFUSOPORCA34' {...register('reference')}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-3 flex-row align-center"><label htmlFor="" className="form-label">Estoque Inicial*</label></div>
                        <div className="col-4">
                            <input type="tel" className={`form-control ${errors.stock && 'error'}`} placeholder='Ex: 100' {...register('stock')}/>
                        </div>
                    </div>
                    <div className="form-row mt-30">
                        <button type="submit" className="btn btn-sm btn-primary mr-15">Salvar</button>
                        <NavLink to="/produtos" className="btn btn-sm btn-white">Cancelar</NavLink>
                    </div>
                </form>
            </CardComponent>
        </>
    )
}