import {useEffect, useState} from 'react'
import { NavLink } from "react-router-dom";
import { CardComponent } from "../../../components/CardComponent";
import { Head } from "../../../components/Head";
import { TituloDescricao } from "../../../components/PagesTitles/style";

import {TbEdit, TbNewSection, TbTrash} from 'react-icons/tb'
import api from '../../../services/api';

interface UsersListProps {
    id: string
    name: string
    email: string
}

export default function ListarUsuarios(){
    const [users, setUsers] = useState<Array<UsersListProps>>([])
    useEffect(() => {
        (async () => {
            const usersData = await api.get('/users.php')
            setUsers(usersData.data)
        })()
    }, [])

    return (
        <>
            <Head title="Usuários" />
            <TituloDescricao>
                <div>
                    <h1>Usuários</h1>
                    <p>Defina quais as pessoas que tem acesso ao sistema</p>
                </div>
                <NavLink className="btn btn-primary" to="/usuarios/adicionar">
                    <TbNewSection/>
                    Novo usuário
                </NavLink>
            </TituloDescricao>

            <CardComponent>
                {users.length ? 
                    <table className="table">
                        <tr>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th>Ações</th>
                        </tr>
                        {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>
                                <NavLink to={`/usuarios/editar/${user.id}`} className="btn-action"><TbEdit /></NavLink>
                                <button className="btn-action danger"><TbTrash /></button>
                            </td>
                        </tr>
                        ))}
                        
                    </table>
                    : 
                    <div className="pd-25 text-center">
                        <h2 className="mb-15">Nenhum registro encontrado</h2>
                        <p>Comece adicionando o primeito item </p>
                    </div>
                }
            </CardComponent>
        </>
    )
}