import { NavLink } from "react-router-dom";

import image from '../../assets/images/errorpage.gif'

export default function Error404(){
    return (
        <div id="erro">
            <div id="erro-content">
                <div>
                    <span>Erro 404</span>
                    <h1>Página não encontrada 😭</h1>
                    <p>Parece que não encontramos a página que procura</p>

                    <div><NavLink to="/" className="btn btn-sm btn-primary">Voltar para o Inicio</NavLink></div>
                </div>
                <div>
                    <img src={image} alt="" />
                </div>
            </div>
        </div>
    )
}