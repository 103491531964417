import styled from 'styled-components'

export const TituloDescricao = styled.header`
    margin-bottom: 50px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
        font-size: 2rem;
        margin-bottom: 10px;
    }

    p {
        font-size: 0.875rem;
        color: #bfbfbf;
    }
`