import { NavLink } from "react-router-dom";
import { CardComponent } from "../../../components/CardComponent";
import { Head } from "../../../components/Head";
import { TituloDescricao } from "../../../components/PagesTitles/style";

import {TbEdit, TbNewSection, TbTrash} from 'react-icons/tb'
import { BiSearchAlt } from 'react-icons/bi'
import { useEffect, useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface ProdutosListaProps {
    id: string
    reference: string
    name: string
}

export default function ListarProdutos(){
    const [products, setProducts] = useState<Array<ProdutosListaProps>>([])
    const [searchTerm, setSearchTerm] = useState<string>('')

    useEffect(() => {
        (async () => {
            const productsData = await api.get(`/products.php?s=${searchTerm}`);
            setProducts(productsData.data)
        })()
    }, [searchTerm])

    //Remove
    const deleteProduct = (id: string|number) => {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Deseja mesmo excluir?',
            text: 'Esta ação é irreversivel',
            showDenyButton: true,
            icon: 'warning',
            confirmButtonText: 'Sim',
            denyButtonText: 'Não'
        }).then(async (result) => {
            if(result.value){
                await api.delete(`/products.php?id=${id}`);
                const productsData = await api.get('/products.php');
                setProducts(productsData.data)
                toast.success('Produto removido com sucesso!')
            }
        })
    }

    return (
        <>
            <Head title="Produtos" />
            <TituloDescricao>
                <div>
                    <h1>Produtos</h1>
                    <p>Parafusos, porcas, arruelas, eletrodos etc</p>
                </div>
                <NavLink className="btn btn-primary" to="/produtos/adicionar">
                    <TbNewSection/>
                    Novo cadastro
                </NavLink>
            </TituloDescricao>

            <CardComponent>
                <form>
                    <div className="col-3 pd-15">
                        <div className="input-icon">
                            <BiSearchAlt />
                            <input type="text" placeholder="Digite para buscar..." className="form-control" onChange={(e) => setSearchTerm(e.currentTarget.value)} />
                        </div>
                    </div>
                </form>
                {products.length ? 
                <table className="table">
                    <tr>
                        <th>#</th>
                        <th>Produto</th>
                        <th>Quantidade</th>
                        <th>Ações</th>
                    </tr>
                    {products.map((product) => 
                    <tr key={product.id}>
                        <td>{product.reference}</td>
                        <td>{product.name}</td>
                        <td>-</td>
                        <td>
                            <NavLink to={`/produtos/editar/${product.id}`} className="btn-action"><TbEdit /></NavLink>
                            <button className="btn-action danger" onClick={() => deleteProduct(product.id)}><TbTrash /></button>
                        </td>
                    </tr>
                    )}
                </table>
                :
                <div className="pd-25 text-center">
                    <h2 className="mb-15">Nenhum registro encontrado</h2>
                    <p>Comece adicionando o primeito item </p>
                </div>
                }
            </CardComponent>
        </>
    )
}