import { createContext, useEffect, useState } from 'react'
import { AuthUserProps } from '../interfaces/auth'
import api from '../services/api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

interface AuthContextProps {
    auth: boolean
    user: AuthUserProps | undefined
    login: (email: string,password: string) => Promise<boolean>
    logout: () => void
    loading: boolean
}

interface AuthContextProviderProps {
    children: React.ReactNode
}

export const AuthContext = createContext({} as AuthContextProps);

export const AuthContextProvider = ({children}: AuthContextProviderProps) => {

    const navigate = useNavigate()

    const [user, setUser] = useState<AuthUserProps>()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const localUser = localStorage.getItem('user')
        if(localUser){
            setUser(JSON.parse(localUser))
        }
        setLoading(false)
    }, [])

    const login = async (email: string,password: string) => {
        const loginPost = await api.post('/auth.php', {
            email,
            password
        })
        if(loginPost.data.success){

            const userData = {
                id: loginPost.data.data.id,
                name: loginPost.data.data.name,
                email: loginPost.data.data.email
            }

            localStorage.setItem('user', JSON.stringify(userData))

            setUser(userData)
            return true
        }
        return false
    }
    const logout = () => {
        setUser(undefined)
        localStorage.removeItem('user')
        toast.success('Deslogado com sucesso!')
        navigate('/login')
    }

    return (
        <AuthContext.Provider value={{auth: !!user, loading, user, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}