import * as yup from "yup";

export const userSchema = yup.object({
    name: yup.string().required('Campo obrigatório'),
    email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
    password: yup.string().required('Campo obrigatório'),
    password_equal: yup.string().test('passwords-match', 'Senhas não conferem', function(value){
        return this.parent.password === value
    })
})

export type UserType = yup.InferType<typeof userSchema>