import { CardComponent } from "../../../components/CardComponent";
import { Head } from "../../../components/Head";
import { TituloDescricao } from "../../../components/PagesTitles/style";

import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { UserType, userSchema } from "./FormValidation";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { useEffect } from "react";

export default function EditarUsuarioPage(){
    const {id} = useParams();
    useEffect(() => {
        (async () => {
            const users = await api.get(`/users.php?id=${id}`)
            setValue('name', users.data.name);
            setValue('email', users.data.email);
        })()
    }, [])

    const navigate = useNavigate();

    async function onSubmit(data: UserType){
        const addUser = await api.put(`/users.php?id=${id}`, data);
        if(addUser.data.success === true){
            toast.success(addUser.data.message)
            navigate('/usuarios');
        }else{
            toast.error(addUser.data.message)
        }
    }

    const {register, handleSubmit, formState: {errors}, setValue} = useForm({resolver: yupResolver(userSchema)})

    return (
        <>
            <Head title="Usuarios" />
            <TituloDescricao>
                <div>
                    <h1>Editar Usuario</h1>
                    <p>Editar um usuario do sistema</p>
                </div>
            </TituloDescricao>

            <CardComponent className="padding-15">
                <header>
                    <h3>Cadastro</h3>
                    <p>Preencha os campos abaixo com os dados para o alterar</p>
                </header>
                <form onSubmit={handleSubmit(onSubmit)} className="col-6">
                    <div className="form-row">
                        <div className="col-3 flex-row align-center"><label htmlFor="" className="form-label">Nome*</label></div>
                        <div className="col-9"><input type="text" className={`form-control ${errors.name && 'error'}`} placeholder='Ex: Tony Stark' {...register('name')}/></div>
                    </div>
                    <div className="form-row">
                        <div className="col-3 flex-row align-center"><label htmlFor="" className="form-label">E-mail*</label></div>
                        <div className="col-9"><input type="text" className={`form-control ${errors.email && 'error'}`} placeholder='Ex: tony@starkindustries.com' {...register('email')}/></div>
                    </div>
                    <div className="form-row">
                        <div className="col-3 flex-row align-center"><label htmlFor="" className="form-label">Senha*</label></div>
                        <div className="col-9"><input type="password" className={`form-control ${errors.password && 'error'}`} placeholder='******' {...register('password')}/></div>
                    </div>
                    <div className="form-row">
                        <div className="col-3 flex-row align-center"><label htmlFor="" className="form-label">Repita a Senha*</label></div>
                        <div className="col-9"><input type="password" className={`form-control ${errors.password_equal && 'error'}`} placeholder='******' {...register('password_equal')}/></div>
                    </div>
                    <div className="form-row mt-30">
                        <button type="submit" className="btn btn-sm btn-primary mr-15">Salvar</button>
                        <NavLink to="/usuarios" className="btn btn-sm btn-white">Cancelar</NavLink>
                    </div>
                </form>
            </CardComponent>
        </>
    )
}