import styled from 'styled-components'

export const Page = styled.main`
    
`

export const ContentLayout = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const ContentPage = styled.div`
    width: calc(100% - 150px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: #fff;
`