import styled from 'styled-components'

export const Card = styled.div`
    background-color: #191c24;
    color: #bfbfbf;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: #313131 0px 0px 2px 0px, #0a0a0a 0px 12px 24px -4px;
    border-radius: 16px;
    overflow: hidden;

    header {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #2b2b2b;
        
        h3 {
            color: #ffffff;
            margin-bottom: 1.125rem;
            text-transform: capitalize;
        }

        p {
            color: #bfbfbf;
            font-weight: 300;
            font-size: 0.875rem;
        }
    }
    
`