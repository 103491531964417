import {TbLogout2, TbBasketFilled, TbArrowsLeftRight, TbUsers} from 'react-icons/tb'
import { Actions, Logo, Menu, Nav } from './style'
import { NavLink } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

export function NavComponent(){

    const {logout} = useAuth()

    return (
        <Nav>
            <div id="menu1">
                <Logo>
                    <h1>Steel<br/>Line</h1>
                </Logo>
                <Menu>
                    <NavLink to="produtos">
                        <TbBasketFilled />
                        Produtos
                    </NavLink>
                    <NavLink to="movimentos">
                        <TbArrowsLeftRight />
                        Movimentos
                    </NavLink>
                    <NavLink to="usuarios">
                        <TbUsers />
                        Usuários
                    </NavLink>
                </Menu>
            </div>
            <Actions>
                <button onClick={() => logout()} >
                    <TbLogout2 />
                    Sair
                </button>
            </Actions>
        </Nav>
    )
}