import styled from 'styled-components'

export const Nav = styled.nav`
    background: #191c24;
    color: #fff;
    width: 100px;
    padding: 20px;
    border-radius: 25px;
    position: fixed;
    top: 20px;
    left: 20px;

    display: flex;
    flex-direction: column;
    height: calc(100vh - 40px);

    > div#menu1 {
        flex: 1;
    }
`

export const Logo = styled.div`
    text-align: center;
    margin-top: 20px;

    h1 {
        font-size: 1rem;
        text-transform: uppercase;
    }
`

export const Menu = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    a, button {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        text-decoration: none;
        color: #fff;
        font-size: 0.75rem;
        opacity: 0.7;

        transition: all ease .3s;

        svg {
            width: 25px;
            height: 25px;
            margin-bottom: 5px;
        }

        &:hover, &.active {
            opacity: 1;
        }
    }
`

export const Actions = styled.div`
    a, button {
        cursor: pointer;
        background: none;
        border: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        text-decoration: none;
        color: #fff;
        font-size: 0.75rem;
        opacity: 0.7;

        transition: all ease .3s;
        
        svg {
            width: 25px;
            height: 25px;
            margin-bottom: 5px;
        }

        &:hover {
            opacity: 1;
        }
    }
`