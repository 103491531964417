import { Outlet } from "react-router-dom";
import { NavComponent } from "../../components/Nav";
import { ContentLayout, Page, ContentPage } from "./style";


export default function LayoutPage(){
    return (
        <Page>
            {/* <HeaderComponent name="" /> */}
            <ContentLayout>
                <NavComponent />
                <ContentPage>
                    <Outlet />
                </ContentPage>
            </ContentLayout>
        </Page>
    )
}