import { Card } from "./style";

interface CardComponentProps {
    children: React.ReactNode
    className?: string
}

export function CardComponent({children, className = ''}: CardComponentProps){
    return (
        <Card className={className}>
            {children}
        </Card>
    )
}