import { CardComponent } from "../../../components/CardComponent";
import { Head } from "../../../components/Head";
import { TituloDescricao } from "../../../components/PagesTitles/style";

import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { ProductType, productSchema } from "./FormValidation";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useEffect } from "react";

export default function EditarProdutoPage(){
    const {id} = useParams();
    useEffect(() => {
        (async () => {
            const product = await api.get(`/products.php?id=${id}`)
            setValue('name', product.data.name);
            setValue('reference', product.data.reference);
        })()
    }, [])

    const navigate = useNavigate();

    const {register, handleSubmit, formState: {errors}, setValue} = useForm({resolver: yupResolver(productSchema)})
    
    async function onSubmit(data: ProductType){
        const addProduct = await api.put(`/products.php?id=${id}`, data);
        if(addProduct.data.success === true){
            toast.success(addProduct.data.message)
            navigate('/produtos');
        }else{
            toast.error(addProduct.data.message)
        }
    }

    return (
        <>
            <Head title="Produtos" />
            <TituloDescricao>
                <div>
                    <h1>Editar Produto</h1>
                    <p>Edite um produto existente</p>
                </div>
            </TituloDescricao>

            <CardComponent className="padding-15">
                <header>
                    <h3>Edição</h3>
                    <p>Preencha os campos abaixo com os dados para alterar</p>
                </header>
                <form onSubmit={handleSubmit(onSubmit)} className="col-6">
                    <div className="form-row">
                        <div className="col-3 flex-row align-center"><label htmlFor="" className="form-label">Nome do produto*</label></div>
                        <div className="col-9">
                            <input 
                                type="text" 
                                className={`form-control ${errors.name && 'error'}`} 
                                placeholder='Ex: Parafuso com porca 3/4"' 
                                {...register('name')} 
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-3 flex-row align-center"><label htmlFor="" className="form-label">Código*</label></div>
                        <div className="col-9">
                            <input type="text" className={`form-control ${errors.reference && 'error'}`} placeholder='Ex: PARAFUSOPORCA34' {...register('reference')}/>
                        </div>
                    </div>
                    <div className="form-row mt-30">
                        <button type="submit" className="btn btn-sm btn-primary mr-15">Salvar</button>
                        <NavLink to="/produtos" className="btn btn-sm btn-white">Cancelar</NavLink>
                    </div>
                </form>
            </CardComponent>
        </>
    )
}