import React from 'react';
import ReactDOM from 'react-dom/client';
import './reset.css';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes2 } from './Routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <RouterProvider router={AppRoutes} /> */}

    <BrowserRouter>
      <AppRoutes2/>
    </BrowserRouter>

    <ToastContainer />
  </React.StrictMode>
);

