import { CardComponent } from "../../../components/CardComponent";
import { Head } from "../../../components/Head";
import { TituloDescricao } from "../../../components/PagesTitles/style";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { MovimentoType, movimentoSchema } from "./FormValidation";
import { NavLink, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { SelectSearch } from "../../../components/SelectSearchProduct";


export default function AdicionarMovimentoPage(){
    const navigate = useNavigate()

    async function onSubmit(data: MovimentoType){
        const addMove = await api.post('/movements.php', {
            product_id: data.product_id.value,
            qty: data.qty,
            operation: data.operation,
            notes: data.notes
        });
        if(addMove.data.success === true){
            toast.success(addMove.data.message)
            navigate('/movimentos');
        }else{
            toast.error(addMove.data.message)
        }
    }
    const {register, handleSubmit, formState: {errors}, control} = useForm({resolver: yupResolver(movimentoSchema)})
    console.log(errors)

    return (
        <>
            <Head title="Movimentos" />
            <TituloDescricao>
                <div>
                    <h1>Novo Movimento</h1>
                    <p>Cadastre um novo Movimento</p>
                </div>
            </TituloDescricao>

            <CardComponent className="padding-15">
                <header>
                    <h3>Cadastro</h3>
                    <p>Preencha os campos abaixo com os dados para inserir um movimento de entrada ou saída de material</p>
                </header>
                <form onSubmit={handleSubmit(onSubmit)} className="col-6">
                    <div className="form-row">
                        <div className="col-3 flex-row"><label htmlFor="" className="form-label">Produto*</label></div>
                        <div className="col-9">
                            <Controller 
                                control={control}
                                name="product_id"
                                render={({field}) => (
                                    <SelectSearch field={field} />
                                )}
                            />
                            <small>O produto deve estar previamente cadastrado</small>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-3 flex-row"><label htmlFor="" className="form-label">Tipo de movimento*</label></div>
                        <div className="col-9">
                            <select className={`form-control ${errors.operation && 'error'}`} {...register('operation')}>
                                <option value="">Selecione</option>
                                <option value="in">Entrada</option>
                                <option value="out">Saída</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-3 flex-row"><label htmlFor="" className="form-label">Quantidade*</label></div>
                        <div className="col-4">
                            <input type="tel" className={`form-control ${errors.qty && 'error'}`} placeholder='Ex: 100' {...register('qty')}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-3 flex-row"><label htmlFor="" className="form-label">Observação</label></div>
                        <div className="col-9">
                            <textarea rows={3} placeholder="Ex: Entrada/Saída/Sobra da obra para a cobertura da Torre dos Vingadores" {...register('notes')}></textarea>
                        </div>
                    </div>
                    <div className="form-row mt-30">
                        <button type="submit" className="btn btn-sm btn-primary mr-15">Salvar</button>
                        <NavLink to="/movimentos" className="btn btn-sm btn-white">Cancelar</NavLink>
                    </div>
                </form>
            </CardComponent>
        </>
    )
}