import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { loginSchema, FormLoginType } from './FormValidation'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

export default function LoginPage(){
    const navigate = useNavigate()
    const {register, handleSubmit, formState: {errors}} = useForm({resolver: yupResolver(loginSchema)})

    const {login} = useAuth()
    const onSubmit = async (data: FormLoginType) => {
        const authStatus = await login(data.email,data.senha)
        if(!authStatus){
            toast.error('E-mail ou senha inválidos')
        }else{
            navigate('/')
        }
    }

    return (
        <div id="login">
            <h1>Steel Line</h1>
            <div id="content-login">
                <h2>Entrar</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <input type="text" id="email" placeholder="E-mail" {...register("email")}/>
                    </div>
                    <div>
                        <input type="password" id="" placeholder="Senha" {...register("senha")}/>
                    </div>
                    <div>
                        <button type="submit">Fazer login</button>
                    </div>
                </form>
            </div>
            <span>Desenvolvido por <a href="https://drcode.com.br">Drcode</a>.</span>
        </div>
    )
}